<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12">
        <ChatComponent
          :current-user-id="currentUserId"
          :rooms="rooms"
          :roomsLoaded="roomsLoaded"
        ></ChatComponent>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChatComponent from '../../components/chat/ChatComponent'
import { mapGetters } from 'vuex'
export default {
  name: 'UserChat',
  components: { ChatComponent },
  data () {
    return {
      rooms: [],
      roomsLoaded: false,
      currentUserId: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'userAuth/user'
    })
  },
  created () {
    this.roomsLoaded = true
    this.$http.get(`users/${this.user.id}/chats`)
      .then(res => {
        this.rooms = res.data.map(room => {
          room.roomId = room.id
          room.roomName = `Poptávka č. ${room.demandId}`

          room.user._id = room.user.id
          room.user.username = room.user.email
          room.host._id = room.host.id
          room.host.username = `${room.host.firstName} ${room.host.lastName}`
          room.users = [room.user, room.host]
          return room
        })

        this.roomsLoaded = true
      })
    this.currentUserId = this.user.id
  }
}
</script>

<style scoped>

</style>
